<template>
  <div>
    <el-scrollbar>
      <el-main>
        <el-form :model="dataForm" :rules="rules" ref="formLayout" label-width="200px"
                 class="demo-ruleForm form-container"
                 style="margin-top: 20px; width: 99%;">
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="企业名称" prop="companyName">
                <template slot="label">
                  <span>企业名称</span>
                </template>
                <el-input
                  disabled="true"
                  v-model="dataForm.companyName"
                  controls-position="right"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统计月份" prop="dateAndTime">
                <el-date-picker
                  v-model="dataForm.dateAndTime"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  :disabled="type === 'view' || type != 'add'"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="custom-row" :gutter="20">
            <el-col :span="12">
              <el-form-item label="填报日期" prop="updateTime">
                <template slot="label">
                  <span>填报日期:</span>
                </template>
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dataForm.updateTime"
                  type="date"
                  disabled="true"
                  placeholder="保存后自动生成"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月生态环保投入（万元）" prop="safeInvest">
                <el-input
                  v-model="dataForm.safeInvest"
                  controls-position="right"
                  maxlength="10"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.safeInvestS<0 || dataForm.safeInvestS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.safeInvestS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月企业开展环保培训次数" prop="trainTimes">
                <el-input
                  v-model="dataForm.trainTimes"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.traintimesS<0 || dataForm.traintimesS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.traintimesS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月参加环保培训人数" prop="trainPeopleCnt">
                <el-input
                  v-model="dataForm.trainPeopleCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.trainPeopleCntS<0 || dataForm.trainPeopleCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.trainPeopleCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月检查数" prop="checkCnt">
                <el-input
                  v-model="dataForm.checkCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.checkCntS<0 || dataForm.checkCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.checkCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月排查出环保隐患数量" prop="hdCnt">
                <el-input
                  v-model="dataForm.hdCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.hdCntS<0 || dataForm.hdCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.hdCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其中排查出重大隐患数量" prop="majorHDCnt">
                <el-input
                  v-model="dataForm.majorHDCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.majorHDCntS<0 || dataForm.majorHDCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.majorHDCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月整改完成数量" prop="rectifiedHdCnt">
                <el-input
                  v-model="dataForm.rectifiedHdCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.rectifiedHdCntS<0 || dataForm.rectifiedHdCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.rectifiedHdCntS" placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月应急演练次数" prop="emergencDrillCnt">
                <el-input
                  v-model="dataForm.emergencDrillCnt"
                  controls-position="right"
                  maxlength="8"
                  show-word-limit
                  :readonly="type == 'view'"
                  style="width: calc(100% - 30px)"
                ></el-input>
                <el-tooltip class="item" effect="light" :content="dataForm.emergencyDrillCntS<0 || dataForm.emergencyDrillCntS == null ||false?'上次填报数据：0':'上次填报数据：' + dataForm.emergencyDrillCntS"
                            placement="bottom">
                  <i class="el-icon-warning-outline iconLine"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button v-if="type!='view'" @click="headSave">保 存</el-button>
      <el-button v-if="type!='view'" type="primary" @click="headSubmit">提 交</el-button>
      <el-button v-if="type == 'view'" type="primary" @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {saveOrUpdate, detail, save, summaryClickDataState} from "@/api/report/safetyProductionMonthly";
import {detail as getProcessDetail, processList as getProcessList} from "@/api/plugin/workflow/process";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {mapGetters} from "vuex";

export default {
  props: {
    rowQuery: {
      type: Object
    }
  },
  name: "protocolEdit",
  mixins: [exForm, draft],
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        dateSource: "1",
      },
      nodeId: '',
      formLoading: false,
      processTemplateKey: "report_ecological",
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      rules: {
        dateAndTime: [
          {required: true, message: '请选择填报年月', trigger: ['blur']},
        ],
        safeInvest: [
          { required: true, message: '请输入本月安全生产投入（万元）', trigger: 'blur' },
          { pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位' }
        ],
        trainTimes: [
          {required: true, message: '请输入本月企业开展环保培训次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        trainPeopleCnt: [
          {required: true, message: '请输入本月参加环保培训人数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        checkCnt: [
          {required: true, message: '请输入本月检查数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        hdCnt: [
          {required: true, message: '请输入本月排查出环保隐患数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        majorHDCnt: [
          {required: true, message: '请输入重大隐患项数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        rectifiedHdCnt: [
          {required: true, message: '请输入本月整改完成数量', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
        emergencDrillCnt: [
          {required: true, message: '请输入本月应急演练次数', trigger: ['blur']},
          {pattern: /^[0-9]+$/, message: '请输入正整数'}
        ],
      },
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),
    refresh() {
      return this.$store.state.common.report
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: "提交",
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: 'submit',
          }
        );
      }
      return result;
    },
    formColumn() {
      return [
        {
          label: "企业名称",
          prop: 'companyName',
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          readonly: true,
        },
        {
          placeholder: false,
          label: "统计月份",
          prop: 'dateAndTime',
          type: "month",
          labelWidth: 240,
          span: 12,
          showWordLimit: true,
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "统计月份",
              trigger: "blur"
            }
          ],
        },
        {
          placeholder: false,
          label: "本月生态环保投入（万元）",
          prop: 'safeInvest',
          labelWidth: 240,
          span: 12,
          maxlength: 10,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            { pattern: /^\d+(\.\d{1,4})?$/, message: '请输入整数或小数，小数点后最多支持四位' },
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月安全生产投入",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月企业开展环保培训次数",
          prop: 'trainTimes',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月企业开展环保培训次数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月参加环保培训人数",
          prop: 'trainPeopleCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月参加环保培训人数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月检查数",
          prop: 'checkCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月检查数",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月排查出环保隐患数量",
          prop: 'hdCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月排查出环保隐患数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "其中排查出重大隐患数量",
          prop: 'majorHDCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "其中排查出重大隐患数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月整改完成数量",
          prop: 'rectifiedHdCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月整改完成数量",
              trigger: "blur"
            }
          ],
        }, {
          placeholder: false,
          label: "本月应急演练次数",
          prop: 'emergencDrillCnt',
          labelWidth: 240,
          span: 12,
          maxlength: 6,
          showWordLimit: true,
          type: 'input',
          min: 0,
          readonly: !['add', 'edit', 'workflow'].includes(this.type),
          rules: [
            {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + "本月应急演练次数",
              trigger: "blur"
            }
          ],
        }
      ]
    },
  },
  created() {
    // this.getProcessId();
    let {unitName, unitCode, type, id, nodeId} = this.rowQuery;
    this.type = type;
    this.nodeId = nodeId
    this.dataForm.companyName = unitName
    this.dataForm.unitCode = unitCode
    if (['view', 'edit'].includes(this.type)) {
      if(id){
        this.dataForm.id = id
        this.createData()
      }
    }
  },
  methods: {
    headCancel() {
      this.$emit('closeDialog');
    },
    restRefresh() {
      this.$store.commit('SET_REPORT', !this.refresh)
    },
    initData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.status == -1) {
          item.status = 1
        }
        item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
        item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
        item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
        item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
        item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
        item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
        item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
        item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
        this.dataForm = item;
      })
    },
    createData() {
      detail(this.dataForm.id).then(res => {
        const item = res.data.data;
        if (item.status == -1) {
          item.status = 1
        } else if (item.status == 4 && this.type == 'edit') {
          this.dataForm = item
          this.dataForm.id = null;
          this.dataForm.dateAndTime = null;
          this.dataForm.safeInvest = null
          this.dataForm.trainTimes = null
          this.dataForm.trainPeopleCnt = null
          this.dataForm.checkCnt = null
          this.dataForm.hdCnt = null
          this.dataForm.rectifiedHdCnt = null
          this.dataForm.majorHDCnt = null
          this.dataForm.emergencDrillCnt = null
          this.dataForm.safeWorkHoursS == -1 ? item.safeWorkHoursS = null : item.safeWorkHoursS
          this.dataForm.safeInvestS == -1 ? item.safeInvestS = null : item.safeInvestS
          this.dataForm.traintimesS == -1 ? item.traintimesS = null : item.traintimesS
          this.dataForm.trainPeopleCntS == -1 ? item.trainPeopleCntS = null : item.trainPeopleCntS
          this.dataForm.checkCntS == -1 ? item.checkCntS = null : item.checkCntS
          this.dataForm.hdCntS == -1 ? item.hdCntS = null : item.hdCntS
          this.dataForm.rectifiedHdCntS == -1 ? item.rectifiedHdCntS = null : item.rectifiedHdCntS
          this.dataForm.majorHDCntS == -1 ? item.majorHDCntS = null : item.majorHDCntS
          this.dataForm.emergencyDrillCntS == -1 ? item.emergencyDrillCntS = null : item.emergencyDrillCntS
          this.dataForm.processInstanceId = null
          this.dataForm.status = null
        } else {
          item.safeInvest == -1 ? item.safeInvest = null : item.safeInvest
          item.trainTimes == -1 ? item.trainTimes = null : item.trainTimes
          item.trainPeopleCnt == -1 ? item.trainPeopleCnt = null : item.trainPeopleCnt
          item.checkCnt == -1 ? item.checkCnt = null : item.checkCnt
          item.hdCnt == -1 ? item.hdCnt = null : item.hdCnt
          item.rectifiedHdCnt == -1 ? item.rectifiedHdCnt = null : item.rectifiedHdCnt
          item.majorHDCnt == -1 ? item.majorHDCnt = null : item.majorHDCnt
          item.emergencDrillCnt == -1 ? item.emergencDrillCnt = null : item.emergencDrillCnt
          item.safeWorkHours == -1 ? item.safeWorkHours = null : item.safeWorkHoursS
          item.safeInvestS == -1 ? item.safeInvestS = null : item.safeInvestS
          item.traintimesS == -1 ? item.traintimesS = null : item.traintimesS
          item.trainPeopleCntS == -1 ? item.trainPeopleCntS = null : item.trainPeopleCntS
          item.checkCntS == -1 ? item.checkCntS = null : item.checkCntS
          item.hdCntS == -1 ? item.hdCntS = null : item.hdCntS
          item.rectifiedHdCntS == -1 ? item.rectifiedHdCntS = null : item.rectifiedHdCntS
          item.majorHDCntS == -1 ? item.majorHDCntS = null : item.majorHDCntS
          item.emergencyDrillCntS == -1 ? item.emergencyDrillCntS = null : item.emergencyDrillCntS
          this.dataForm = item;
        }
      })
    },
    headSave(cancel = false) {
      this.formLoading = true;
      this.$refs.formLayout.validate((valid) => {
        if (valid) {
          this.dataForm.statType = "ENV"
          this.dataForm.dateSource = "fill"
          this.dataForm.processInstanceId = null
          saveOrUpdate({
            ...this.dataForm,
          }, this.type)
            .then(res => {
              const {msg, data} = res.data;
              this.dataForm = data
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            }).finally(() => {
          })
        }
      })
      this.formLoading = false;
    },
    headSubmit(row) {
      // if (!(this.dataForm.status == 2 || this.dataForm.status == 3 || this.dataForm.status == 4)) {
        this.$refs.formLayout.validate((valid) => {
          if (valid) {
            // if (this.dataForm.id) {
            var row = this.dataForm;
            this.dataForm.statType = "ENV"
            this.dataForm.dateSource = "fill"
            this.dataForm.processInstanceId = null
            this.$loading();
            saveOrUpdate({
              ...this.dataForm
            }).then((res) => {
              if (res.data.code === 200) {
                this.dataForm = res.data.data;
              }
            }).then(() => {
              this.$loading()
              this.dataForm.templateCode = "ecologicalProductionMonthly"
              summaryClickDataState(this.dataForm).then(res => {
                if (res.data.code === 200) {
                  if (res.data.data) {
                    this.$confirm("下级还有数据未通过，是否继续提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    }).then(() => {
                      this.$confirm("确定将提交?", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                      })
                        .then(() => {
                          this.rpTaskAttributeSubmit(this.dataForm);
                        }).catch(() => {
                      })
                        .finally(() => {
                        });
                    });
                  } else {
                    this.$confirm("确定将提交?", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning"
                    })
                      .then(() => {
                        this.rpTaskAttributeSubmit(this.dataForm);
                      }).catch(() => {
                    })
                      .finally(() => {
                      });
                  }
                }
              }).finally(() => {
              })
            }).finally(() => {
              this.$loading().close()
            })
            // } else {
            //   this.$message.warning("请先保存数据");
            // }
          }
        })
      // } else {
      //   this.$message.warning("请先保存数据");
      // }
    },
    rpTaskAttributeSubmit(data) {
      this.$loading();
      this.dataForm.status = 4;
      save(this.dataForm).then((res) => {
        if (res.data.code === 200) {
          this.$loading().close();
          this.$emit("closeDialog")
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.restRefresh();
          this.initData();
        }
      }).catch((err) => { this.$loading().close();})
          .finally(() => { this.$loading().close();});
      // this.formProcess.id = data.id;
      // this.formProcess.deptId = this.nodeId
      // this.handleStartProcess5(true,true).then((res) => {
      //   let processInstanceId = res.data.data;
      //   this.dataForm.processInstanceId = processInstanceId;
      //   this.dataForm.status = 2;
      //   getProcessDetail({processInsId: processInstanceId}).then((res) => {
      //     if (res.data.data.flow[0].assigneeIds) {
      //       this.dataForm.assigneeIds = res.data.data.flow[0].assigneeIds
      //     }
      //     save(this.dataForm).then((res) => {
      //       if (res.data.code === 200) {
      //         this.$loading().close();
      //         this.$emit("closeDialog")
      //         this.$message({
      //           message: "操作成功",
      //           type: "success",
      //         });
      //         this.restRefresh();
      //         this.initData();
      //       }
      //     })
      //       .catch((err) => {
      //       })
      //       .finally(() => {
      //       });
      //   });
      // });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
  height: 70vh;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  border-top: 1px solid rgb(204, 204, 204);
}

::v-deep .el-form-item__content {
  display: flex;
}

.item {
  font-size: 24px;
  margin-left: 6px;
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}
</style>
